export const images = [
  'https://live.staticflickr.com/65535/48881738212_4badac465c_h.jpg',
  'https://live.staticflickr.com/4161/34321420422_3b79a9c5c3_h.jpg',
  'https://live.staticflickr.com/65535/52438986499_4c1b6f3017_k.jpg',
  'https://live.staticflickr.com/65535/52791582408_13cf14aa83_n.jpg',
  'https://live.staticflickr.com/65535/52889513701_8d28d2fe21_h.jpg"',
  'https://live.staticflickr.com/65535/52791517235_608bdc773b_n.jpg',
  'https://live.staticflickr.com/65535/50045504998_ddd3e06964_h.jpg',
  'https://live.staticflickr.com/65535/52096075655_efa1b6ffe5_k.jpg',
  'https://live.staticflickr.com/65535/47729673622_e8d1c785ee_h.jpg',
];
